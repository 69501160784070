import useCases from '@/hooks/useCases';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, MouseEvent } from 'react';

interface IProps {
  onClick: (e: MouseEvent<HTMLElement>) => void;
}
const CaseInputEditControl: FC<IProps> = observer(({ onClick }) => {
  const cases = useCases();

  if (cases.isCompleted() || cases.confirmedNote) {
    return null;
  }

  if (cases.noteEditMode || !cases.loaded) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      startIcon={<EditIcon color="secondary" />}
      onClick={onClick}
      sx={{ borderRadius: 6 }}
      className="dd-privacy-allow"
    >
      Rediger
    </Button>
  );
});

export default CaseInputEditControl;
