import { useCallback, useEffect, useRef, useState } from 'react';
import fixWebmDuration from 'webm-duration-fix';
import { setupVolumeProcessor } from './volumeProcessor';
import { getCoPilotAuthToken } from '@/services/BackendAPI';
import { BACKEND_URL } from '@/config/constants';

type UseAudioRecorderReturn = {
  startRecording: (device: MediaDeviceInfo) => void;
  stopRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  resetRecorder: () => void;
  toggleRecording: (device: MediaDeviceInfo) => void;
  audioBlob: Blob | null;
  recordingTime: number;
  state: 'idle' | 'initializing' | 'recording' | 'pausing' | 'paused' | 'stopped';
  dictationError?: string;
};

const wss_backend_url = BACKEND_URL.replace('https', 'wss').replace('/code/', '/audio-bridge/');

export const useStreamedSpeechRec = (addDictation: {
  (dictation: string): void;
}): UseAudioRecorderReturn => {
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [state, setState] = useState<
    'idle' | 'initializing' | 'recording' | 'pausing' | 'paused' | 'stopped'
  >('idle');
  const [time, setTime] = useState<number>(0);

  const recorderRef = useRef<MediaRecorder | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const displayMediaStreamRef = useRef<MediaStream | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);
  const dictationError = useRef<string | null>(null);

  // If recording, update the volume
  useEffect(() => {
    let cleanupAudio: () => void;

    if (state === 'recording' && mediaStreamRef.current) {
      cleanupAudio = setupVolumeProcessor(mediaStreamRef.current, (normalizedVolume) => {
        document.documentElement.style.setProperty('--volume-scale', `${normalizedVolume + 1}`);
        document.documentElement.style.setProperty('--volume-opacity', `${normalizedVolume * 0.5}`);
      });
    } else {
      document.documentElement.style.setProperty('--volume-scale', `1`);
      document.documentElement.style.setProperty('--volume-opacity', `0`);
    }

    return () => {
      if (cleanupAudio) cleanupAudio();
    };
  }, [state, mediaStreamRef]);

  useEffect(() => {
    if (state === 'recording') {
      const intervalId = setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [state]);

  const startRecording = async (device?: MediaDeviceInfo) => {
    setState('initializing');
    // Reset the recorder if it's already running
    if (recorderRef.current) {
      recorderRef.current.stop();
      recorderRef.current = null;
    }

    if (websocketRef.current) {
      websocketRef.current.close();
      websocketRef.current = null;
    }

    let copilotToken: string | null = null;
    try {
      copilotToken = await getCoPilotAuthToken();
      // copilotToken = prompt('Please enter your CoPilot token:');
      // Create a new websocket connection
      websocketRef.current = new WebSocket(
        `${wss_backend_url}v1/transcribe?token=Bearer%20${copilotToken}`,
      );

      // onwebsocket open, send config message
      websocketRef.current.onopen = () => {
        // get "lang" query param from url otherwise use 'da'
        const language = new URLSearchParams(window.location.search).get('lang') || 'da';
        websocketRef.current.send(
          JSON.stringify({
            event: 'START',
            metadata: { language, previewResults: false },
          }),
        );
      };

      // deal with the websocket messages here
      websocketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.data.text && data.data.final) {
          addDictation(data.data.text);
        }
      };
    } catch (error) {
      console.error('Failed to get CoPilot auth token:', error);
      dictationError.current =
        'Talegenkendelsen fungerer desværre ikke i øjeblikket, men din diktat vil stadig blive optaget.';
    }

    // Create a new media stream
    const constraints = {
      audio: {
        deviceId: device?.deviceId ? { exact: device?.deviceId } : undefined,
        voiceIsolation: true,
      },
    };

    // wait until websocket is open before getting the media stream
    const startTime = Date.now();
    while (websocketRef.current?.readyState !== 1) {
      if (Date.now() - startTime > 5000) {
        console.error('WebSocket connection timed out.');
        dictationError.current =
          'Talegenkendelsen fungerer desværre ikke i øjeblikket, men din diktat vil stadig blive optaget.';
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    // if url contains query param 'display', get display media instead of audio (for debugging purposes)
    if (window.location.search.includes('display')) {
      displayMediaStreamRef.current = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: true,
      });
      // get the audio track only
      mediaStreamRef.current = new MediaStream();
      displayMediaStreamRef.current.getAudioTracks().forEach((track) => {
        mediaStreamRef.current?.addTrack(track);
      });
    } else {
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia(constraints);
    }

    // Create a new recorder
    recorderRef.current = new MediaRecorder(mediaStreamRef.current, {
      mimeType: 'audio/webm;codecs=opus',
    });

    recorderRef.current.ondataavailable = (event) => {
      setAudioChunks((prev) => [...prev, event.data]);
      if (event.data.size > 0 && websocketRef.current?.readyState == 1) {
        websocketRef.current.send(event.data);
      } else if (!dictationError.current && websocketRef.current) {
        console.error('webSocket not ready or no data', {
          ws: websocketRef.current,
          dataSize: event.data.size,
        });
        dictationError.current =
          'Talegenkendelsen fungerer desværre ikke i øjeblikket. Din diktat vil stadig blive optaget.';
      }
    };

    // Start recording
    recorderRef.current.start(250);
    setState('recording');
  };

  const stopRecording = async () => {
    if (recorderRef.current) {
      recorderRef.current.stop();

      if (websocketRef.current) {
        if (websocketRef.current?.readyState === 1) {
          websocketRef.current.send(JSON.stringify({ event: 'STOP' }));
        }
        websocketRef.current.close();
        websocketRef.current = null;
      }

      const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
      fixWebmDuration(audioBlob).then((fixedBlob) => {
        setAudioBlob(fixedBlob);
      });

      setAudioChunks([]);

      recorderRef.current = null;
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }
    setState('stopped');

    if (displayMediaStreamRef.current) {
      displayMediaStreamRef.current.getTracks().forEach((track) => track.stop());
      displayMediaStreamRef.current = null;
    }
    return new Promise((resolve) => {
      resolve(true);
    });
  };

  const pauseRecording = () => {
    // wait 2.5 seconds before pausing
    setState('pausing');
    mediaStreamRef.current.getAudioTracks().forEach((track) => {
      track.enabled = false;
    });

    // Here's why we need to wait 3 seconds before pausing: https://github.com/corticph/code-frontend/pull/683
    setTimeout(() => {
      if (recorderRef.current) {
        recorderRef.current.pause();
        setState('paused');
        mediaStreamRef.current.getAudioTracks().forEach((track) => {
          track.enabled = true;
        });
      }
    }, 3000);
  };

  const resumeRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.resume();
      setState('recording');
    }
  };

  const resetRecorder = () => {
    if (recorderRef.current) {
      recorderRef.current.stop();
      recorderRef.current = null;
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    if (websocketRef.current) {
      websocketRef.current.close();
      websocketRef.current = null;
    }

    setState('idle');
    dictationError.current = null;
  };

  const toggleRecording = useCallback(
    (device?: MediaDeviceInfo) => {
      if (state === 'recording') {
        pauseRecording();
      } else if (state === 'paused') {
        resumeRecording();
      } else {
        startRecording(device);
      }
    },
    [state],
  );

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    resetRecorder,
    toggleRecording,
    audioBlob,
    state,
    recordingTime: time,
    dictationError: dictationError.current,
  };
};
