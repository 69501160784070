import useCases from '@/hooks/useCases';
import { autoFocusedFieldRef } from '@/utils/forms';
import { convertCaseUrgencyToXMStatusType } from '@/utils/misc';
import { DialogContentText, FormControlLabel, Switch, TextField, Box } from '@mui/material';
import { transaction } from 'mobx';
import { observer } from 'mobx-react';
import { ChangeEvent, useState } from 'react';
import ConfirmDialog from './ConfirmDialog';
import DuplicateCodesWarning from './DuplicateCodesWarning';
import { EAudioProcessingStep } from '@/types/audio/EAudioProcessingStep';
import { FeedbackGetter } from './FeedbackGetter';
import { amber } from '@mui/material/colors';

interface IFlagDialogProps {
  open: boolean;
  onClose: () => void;
  hasDuplicateCodes?: boolean;
}

export const FlagDialog = observer(
  ({ open, onClose, hasDuplicateCodes = false }: IFlagDialogProps) => {
    const cases = useCases();
    const [flagReason, setFlagReason] = useState('');
    const [isUrgent, setIsUrgent] = useState(false);
    const [isAttest, setIsAttest] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [askForFeedback, _setAskForFeedback] = useState(Math.random() < 0.15);

    const flagAndClear = async () => {
      const status = convertCaseUrgencyToXMStatusType(isUrgent);

      const flagReasonText = isAttest ? `[Attest] ${flagReason}` : flagReason;

      await cases.flagOrCompleteAndSubmitCaseToEHR(
        status,
        isUrgent,
        true,
        flagReasonText,
        cases.confirmedNote,
        isAttest,
      );

      transaction(() => {
        cases.clearCase();
        cases.disableForceRedirectToNewEhr();
        cases.enableForceRedirectToInbox();
      });
    };

    const handleSubmit = async () => {
      setSubmitLoading(true);
      if (!cases.isNew() || !cases.hasRecordingBeenUsed()) {
        await flagAndClear();

        handleClose();
        return;
      }

      await cases.waitForFullAudioAndProcessIt(async () => {
        await flagAndClear();

        handleClose();
      });
      setSubmitLoading(false);
      return;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFlagReason(event.target.value);
    };

    const handleToggleUrgency = (event: ChangeEvent<HTMLInputElement>) => {
      setIsUrgent(event.target.checked);
    };

    const handleToggleAttest = (event: ChangeEvent<HTMLInputElement>) => {
      setIsAttest(event.target.checked);
    };

    const handleToggleConfirmedNote = (event: ChangeEvent<HTMLInputElement>) => {
      cases.updateConfirmedNoteStatus(event.target.checked);
    };

    const handleClose = () => {
      setFlagReason('');
      setIsUrgent(false);
      onClose();
    };

    const label = { inputProps: { 'aria-label': 'Toggle urgency' } };

    return (
      <ConfirmDialog
        title="Review"
        open={open}
        onCancel={handleClose}
        onAccept={handleSubmit}
        isLoading={
          submitLoading ||
          cases.fullAudioProcessingStep === EAudioProcessingStep.STORING ||
          cases.segmentAudioProcessingStep === EAudioProcessingStep.STORING
        }
      >
        {cases.confirmedNote ? (
          <DialogContentText sx={{ background: amber[100], p: 2 }}>
            Du er ved at godkende dit journalnotat uden gennemgang af en lægesekretær. Bemærk, at
            diktatet ikke vil blive lyttet til, og der vil ikke blive foretaget nogen rettelser.
            Lægesekretæren vil dog stadig læse notatet for kodning og opfølgende handlinger. Vær
            sikker på, at dit notat er korrekt og fuldt udfyldt.
          </DialogContentText>
        ) : (
          <DialogContentText>
            Du er ved at sende dit journalnotat til gennemsyn ved en lægesekretær. Fortæl
            lægesekretæren hvad der skal ske med dit notat på baggrund af patientens besøg (f.eks.
            kontrol, MR-scanning, afsluttes):
          </DialogContentText>
        )}
        <TextField
          inputRef={autoFocusedFieldRef}
          autoFocus
          value={flagReason}
          onChange={handleChange}
          autoComplete="off"
          margin="dense"
          id="name"
          label="Bemærkning"
          fullWidth
          variant="standard"
        />
        <Box display="flex" sx={{ flexDirection: 'column', my: 4, gap: 2 }}>
          <Box>
            <FormControlLabel
              sx={{ w: '100%' }}
              control={
                <Switch
                  color="error"
                  checked={isUrgent}
                  onChange={handleToggleUrgency}
                  {...label}
                />
              }
              label="Markér denne sag som hastende"
            />
          </Box>
          <FormControlLabel
            sx={{ w: '100%' }}
            control={
              <Switch
                color="secondary"
                checked={isAttest}
                onChange={handleToggleAttest}
                {...label}
              />
            }
            label="Markér denne sag som Attest"
          />

          <FormControlLabel
            sx={{ w: '100%' }}
            control={
              <Switch
                color="secondary"
                checked={cases.confirmedNote}
                onChange={handleToggleConfirmedNote}
                {...label}
              />
            }
            label="Markér denne sag godkendt"
          />
        </Box>

        {askForFeedback && <FeedbackGetter caseId={cases.caseId} />}

        <DialogContentText>{hasDuplicateCodes && <DuplicateCodesWarning />}</DialogContentText>
      </ConfirmDialog>
    );
  },
);
