import React from 'react';
import { Box, Button } from '@mui/material';
import { SpeechRecButton } from './SpeechRecButton';
import AudioRecordingSourceControl from './AudioRecordingSourceControl';
import AudioPlayer from './AudioPlayer';
import { bottomDivStyle } from './CaseInputv2Styles';

import { formatAudioDurationS } from '@/utils/dates';
import { blue, green, grey } from '@mui/material/colors';

// Define the props type
interface AudioControlsProps {
  state: 'idle' | 'initializing' | 'recording' | 'pausing' | 'paused' | 'stopped';
  recordingTime: number;
  toggleRecording: () => void;
  audioBlob: Blob | null;
  showFinalizeButton: boolean;
  handleFinalizeRecording: () => void;
}

const AudioControls = ({
  state,
  recordingTime,
  toggleRecording,
  audioBlob,
  showFinalizeButton,
  handleFinalizeRecording,
}: AudioControlsProps) => (
  <div style={bottomDivStyle}>
    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={4}>
      {state === 'idle' ? (
        <AudioRecordingSourceControl />
      ) : (
        <RecorderDuration recordingTime={recordingTime} state={state} />
      )}
    </Box>
    <SpeechRecButton state={state} onClick={toggleRecording} />
    {showFinalizeButton && (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button onClick={handleFinalizeRecording} color="success" variant="contained">
          Færdiggør diktat og foreslå koder
        </Button>
      </Box>
    )}
    {audioBlob && <AudioPlayer src={URL.createObjectURL(audioBlob)} />}
  </div>
);

const RecorderDuration = ({ recordingTime, state }: { recordingTime: number; state: string }) => {
  const color = state === 'recording' ? green : state === 'pausing' ? grey : blue;
  return (
    <Box
      sx={{
        color: color[600],
        fontSize: '0.8rem',
        textAlign: 'right',
        fontVariantNumeric: 'tabular-nums',
      }}
      className="dd-privacy-allow"
    >
      {formatAudioDurationS(recordingTime)}
    </Box>
  );
};

export default AudioControls;
