import { Box } from '@mui/material';
import { grey, blue } from '@mui/material/colors';
import { FC, ReactNode } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IProps {
  title: string | ReactNode;
  value: string | number;
  operation?: string;
  linkTo?: string;
  showText?: boolean;
}
const CaseDataBarItem: FC<IProps> = ({ title, value, operation, linkTo, showText = false }) => {
  const isLinked = operation && linkTo && window?.cortiCodeAPI?.runOpenMedicusCommand;

  const handleClick = () => {
    if (!isLinked) return;
    window?.cortiCodeAPI?.runOpenMedicusCommand(operation, `int32:${linkTo}`);
  };
  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: '4px',
        padding: '8px',
        '&:hover': {
          cursor: isLinked ? 'pointer' : 'default',
          color: isLinked ? blue[800] : grey[800],
          backgroundColor: isLinked ? blue[50] : 'transparent',
        },
      }}
    >
      <Box
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '18px',
          letterSpacing: '0.15px',
        }}
        className="dd-privacy-allow"
      >
        {title}
      </Box>
      <Box
        sx={{
          fontSize: '14px',
          color: isLinked ? blue[600] : grey[700],
          maxWidth: '500px',
          wordBreak: 'break-all',
          textAlign: 'left',
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '0.15px',
        }}
        className={showText && 'dd-privacy-allow'}
      >
        {value}
        {isLinked && (
          <OpenInNewIcon
            sx={{
              fontSize: '14px',
              ml: 0.5,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CaseDataBarItem;
