import useCases from '@/hooks/useCases';
import { formatDate } from '@/utils/dates';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import CaseDataBarItem from './CaseDataBarItem';
import { CaseFlagReason } from './CaseFlagReason';

export const CaseDataBar = observer(() => {
  const cases = useCases();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1,
        gap: 3,
      }}
    >
      {cases.isFlagged() && <CaseFlagReason />}
      {cases.caseId && (
        <CaseDataBarItem title="Dato" value={formatDate(cases.createdAt)} showText />
      )}
      {cases.cpr && (
        <>
          <CaseDataBarItem title="Navn" value={cases.patientName} />
          <CaseDataBarItem title="CPR" value={`#${cases.cpr}`} />
        </>
      )}
      {!cases.cpr && cases.patientId && (
        <CaseDataBarItem
          title="Patient ID"
          value={cases.patientId}
          operation="OpenPatient"
          linkTo={cases.patientId}
        />
      )}
      {cases.caseId && <CaseDataBarItem title="Corti ID" value={cases.caseId} showText />}
      {cases.externalId && <CaseDataBarItem title="Journal ID" value={cases.externalId} showText />}
    </Box>
  );
});
